/* CSS Variables for Light and Dark Themes */
:root {
  /* Light Theme Colors */
  --color-background: #ffffff;
  --color-text: #000;
  --color-link: #000;
  --color-link-visited: #777;
  --color-link-hover: #0056b3;
  --color-header-background: #f0f0f3;
  --color-header-text: #000000;
  --color-column-background: #ffffff;
  --color-toggle-button-background: rgba(255, 255, 255, 0.5);
  --color-toggle-button-text: #000000;
  --color-toggle-button-hover: rgba(255, 255, 255, 0.7);
}

body[data-theme='dark'] {
  /* Dark Theme Colors */
  --color-background: #121212;
  --color-text: #ececec;
  --color-link: #e0e0e0;
  --color-link-visited: #999;
  --color-link-hover: #1d83f0;
  --color-header-background: #303030;
  --color-header-text: #ffffff;
  --color-column-background: #1e1e1e;
  --color-toggle-button-background: rgba(0, 0, 0, 0.5);
  --color-toggle-button-text: #ffffff;
  --color-toggle-button-hover: rgba(0, 0, 0, 0.7);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

a {
  color: var(--color-link);
  text-decoration: none;
  transition: color 0.3s;
}

a:visited {
  color: var(--color-link-visited);
  transition: color 0.3s;
}

a:hover {
  text-decoration: underline;
  color: var(--color-link-hover);
}
