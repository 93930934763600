.App {
  max-width: 1100px;
  margin: 0 auto;
  background-color: var(--color-header-background);
}

.content {
  display: flex;
  justify-content: center;
  gap: 30px; /* Consistent spacing between columns */
  padding: 0 20px;
  margin: 0 auto; /* Center the content */
}

/* media query to apply styles at < 768px */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    gap: 0;
  }
}

.theme-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  color: var(--color-toggle-button-text);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  transition: background 0.3s, color 0.3s;
  z-index: 1000; /* Ensure the button is above other elements */
}

.theme-toggle-button:hover {
  background: transparent;
}

/* Loading state styling */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  padding: 20px;
  text-align: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--color-accent);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error state styling */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  padding: 20px;
  text-align: center;
  color: var(--color-text);
}

.error-container h2 {
  margin-bottom: 10px;
  color: #e74c3c;
}

/* Remove the duplicate headline-image styles since they're now in Headline.css */
@media (max-width: 768px) {
  .main-headline-container {
    padding: 0 20px;
  }
}

@media (min-width: 769px) {
  .main-headline-container {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}
