.headline {
  margin-bottom: 35px; /* Spacing between headlines */
  transition: color 0.3s;
}

.headline a {
  font-size: 18px;
  line-height: 1.2;
  transition: color 0.3s;
  text-decoration: none;
  display: block;
}

.main-headline {
  text-align: center;
  transition: color 0.3s;
}

.main-headline a {
  font-size: 26px;
  font-weight: bold;
  transition: color 0.3s;
}

.image-container {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 4px;
  background-color: #f0f0f0;
  aspect-ratio: 16 / 9;
}

.image-loading-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading-animation 1.5s infinite;
}

@keyframes loading-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.headline-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  transition: opacity 0.3s ease;
}

.headline-image.loading {
  opacity: 0;
}

.headline-text {
  margin-top: 8px;
  transition: color 0.3s;
}

.headline-text.no-image {
  font-weight: 500;
  /* Add a subtle indicator that there's no image */
  padding-left: 10px;
  border-left: 3px solid #ccc;
}

/* Responsive Font Sizes */
@media (max-width: 768px) {
  .headline a {
    font-size: 16px;
  }

  .main-headline a {
    font-size: 22px;
  }
}

/* Dark mode adjustments */
[data-theme="dark"] .image-container {
  background-color: #2a2a2a;
}

[data-theme="dark"] .image-loading-placeholder {
  background: linear-gradient(90deg, #2a2a2a 25%, #3a3a3a 50%, #2a2a2a 75%);
  background-size: 200% 100%;
}

[data-theme="dark"] .headline-text.no-image {
  border-left-color: #555;
}
