.column {
  display: flex;
  flex-direction: column;
  background-color: var(--color-header-background);
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

/* media query to apply styles at < 768px */
@media (max-width: 768px) {
  .column {
    padding: 0;
  }
}

@media (min-width: 769px) {
  .column {
    width: 33%;
  }
}
